import {
  Button,
  MenuItem,
  Paper,
  Typography,
  useTheme,
  Box,
  Modal,
  TextField,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  changeEmailRequest,
  changePasswordRequest,
  changePhoneRequest,
  createMyApiKeyRequest,
  getMyApiKeyRequest,
  meRequest,
  updateCorporateUser,
  updateIndividualUser,
  verifyEmailCodeRequest,
  verifyPhoneCodeRequest,
} from "../../api/controllers/account-controller";
import { getAllRequest } from "../../api/controllers/addresses-controller";
import { toast } from "react-toastify";
import { EditRounded } from "@mui/icons-material";
import CustomDialog from "../../components/CustomDialog";

const Page = () => {
  const theme = useTheme();

  /*   const handleCreate = async (e) => {
          e.preventDefault()
  
  
          let res = await saveSafeZoneRequest({ latLngList, safeZoneName })
  
          console.log(res?.data)
  
          await fetchSafeZones()
  
          setCreateModalOpen(false)
  
      } */
  const [email, setEmail] = useState("");
  const [sehir, setSehir] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userType, setUserType] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [user, setUser] = useState(null);

  const [individualEditModalOpen, setIndividualEditModalOpen] = useState(false);
  const [corporateEditModalOpen, setCorporateEditModalOpen] = useState(false);

  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editIndividualProvince, setEditIndividualProvince] = useState("");
  const [illerBireysel, setIllerBireysel] = useState([]);
  const [illerKurumsal, setIllerKurumsal] = useState("");

  const [editTitle, setEditTitle] = useState("");
  const [editCorporateProvince, setEditCorporateProvince] = useState("");

  const [editPhone, setEditPhone] = useState("");
  const [editPhoneModalOpen, setEditPhoneModalOpen] = useState(false);

  const [verifyPhoneCode, setVerifyPhoneCode] = useState("");
  const [verifyPhoneCodeModalOpen, setVerifyPhoneCodeModalOpen] =
    useState(false);

  const [editMail, setEditMail] = useState("");
  const [editMailModalOpen, setEditMailModalOpen] = useState(false);

  const [verifyMailCode, setVerifyMailCode] = useState("");
  const [verifyMailCodeModalOpen, setVerifyMailCodeModalOpen] = useState(false);

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  const fetchProvince = async () => {
    let res = await getAllRequest();
    if (res?.data) {
      console.log(res?.data);
      setIllerBireysel(res.data);
      setIllerKurumsal(res.data);
    }
  };

  const getApiKey = async () => {
    if (
      !(
        userType === "BossUser" ||
        user?.role === "ROLE_BOSS" ||
        user?.role === "ROLE_ADMIN"
      )
    ) {
      return;
    }

    try {
      let res = await getMyApiKeyRequest();
      if (res.data) {
        setApiKey(res.data);
      }
    } catch (error) {
      console.log("API key alma başarısız:", error);
    }
  };

  const apiKeyGenerate = async () => {
    if (
      !(
        userType === "BossUser" ||
        user?.role === "ROLE_BOSS" ||
        user?.role === "ROLE_ADMIN"
      )
    ) {
      toast.error("Bu işlem için yetkiniz bulunmamaktadır.");
      return;
    }

    try {
      let res = await createMyApiKeyRequest();
      if (res) {
        getApiKey();
        toast.success("Api key başarıyla oluşturuldu.");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error("Bu işlem için yetkiniz bulunmamaktadır.");
      } else {
        toast.error("Api key oluşturulurken bir hata oluştu.");
      }
    }
  };

  const fetchAccountInformation = async () => {
    let res = await meRequest();
    if (res?.data) {
      console.log(res?.data);
      setUser(res?.data);
      setEmail(res?.data.email);
      setSehir(res?.data?.province);
      setPhone(res?.data?.phone);
      setTitle(res?.data?.title);
      setFirstName(res?.data?.firstName);
      setLastName(res?.data?.lastName);
      setUserType(res?.data?.userType);
      setEditFirstName(res?.data?.firstName);
      setEditLastName(res?.data?.lastName);
      setEditIndividualProvince(res?.data?.province);
      setEditTitle(res?.data?.title);
      setEditCorporateProvince(res?.data?.province);
      setEditPhone(res?.data?.phone);
      setEditMail(res?.data?.email);
    }
  };

  const handleEditIndividual = async (e) => {
    e.preventDefault();
    try {
      let res = await updateIndividualUser({
        firstName: editFirstName,
        lastName: editLastName,
        province: editIndividualProvince,
      });
      if (res) {
        setEditFirstName("");
        setEditLastName("");
        setEditIndividualProvince("");
      }
      await fetchAccountInformation();
    } catch (error) {}
    setIndividualEditModalOpen(false);
  };

  const handleEditCorporate = async (e) => {
    e.preventDefault();
    try {
      let res = await updateCorporateUser({
        province: editCorporateProvince,
        title: editTitle,
      });
      if (res) {
        setEditCorporateProvince("");
        setEditTitle("");
      }
      await fetchAccountInformation();
    } catch (error) {}
    setCorporateEditModalOpen(false);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword === newPasswordAgain) {
      if (oldPassword.length < 6) {
        toast.warning("Eski Parolanız 6 Haneden Küçük Olamaz");
      } else if (newPassword.length < 6) {
        toast.warning("Yeni Parolanız 6 Haneden Küçük Olamaz");
      } else if (newPasswordAgain.length < 6) {
        toast.warning("Yeni Parola Tekrarınız 6 Haneden Küçük Olamaz");
      }
      try {
        let res = await changePasswordRequest({
          newPassword: newPassword,
          oldPassword: oldPassword,
          repeatNewPassword: newPasswordAgain,
        });
        if (res) {
          setNewPassword("");
          setNewPasswordAgain("");
          setOldPassword("");
        }
      } catch (error) {}
    } else {
      toast.warning("Yeni Parolalar Uyuşmamaktadır");
    }
    setChangePasswordModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      await fetchAccountInformation();
      await fetchProvince();
      if (
        userType === "BossUser" ||
        user?.role === "ROLE_BOSS" ||
        user?.role === "ROLE_ADMIN"
      ) {
        await getApiKey();
      }
    })();

    return () => {};
  }, []);

  return (
    <Box p={3}>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          padding: theme.spacing(2),
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          background: "#FBE9E7",
          boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)",
        }}
      >
        <Typography variant="h5" color="primary" fontWeight={500}>
          Hesabım
        </Typography>
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: "row" }
          }}
        >
          <Button
            onClick={
              userType === "IndividualUser"
                ? () => setIndividualEditModalOpen(true)
                : () => setCorporateEditModalOpen(true)
            }
            disableElevation
            sx={{
              margin: theme.spacing(1),
              width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" },
            }}
            variant="contained"
            color="primary"
          >
            Düzenle
          </Button>
        </div>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          padding: "30px",
          marginTop: theme.spacing(2),
        }}
      >
        <Typography color="#DC804F">Genel Bilgiler</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            width: "100%",
          }}
        >
          {userType === "IndividualUser" && (
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography color="#757575">İsim Soyisim: </Typography>
              <Typography marginLeft="5px" fontWeight="bold" color="#757575">
                {firstName} {lastName}
              </Typography>
            </Box>
          )}
          {userType === "CorporateUser" && (
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography color="#757575">Şirket Unvanı: </Typography>
              <Typography marginLeft="5px" fontWeight="bold" color="#757575">
                {title || "Bilinmiyor"}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src="/assets/LocationOnRounded.svg" />
            <Typography color="#757575">Şehir: </Typography>
            <Typography marginLeft="5px" fontWeight="bold" color="#757575">
              {" "}
              {sehir || "Bilinmiyor"}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginTop: "30px" }}></Divider>
        <Typography marginTop="30px" color="#DC804F">
          İletişim Bilgileri
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "50%", md: "50%", lg: "50%" },
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              alignItems: {
                xs: "baseline",
                sm: "baseline",
                md: "center",
                lg: "center",
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img src="/assets/PhoneRounded.svg" />
              <Typography color="#757575">Telefon Numarası: </Typography>
            </Box>
            <Typography mx="5px" fontWeight="bold" color="#757575">
              {" "}
              {phone || "Bilinmiyor"}
            </Typography>
            {/* <IconButton
                            size="sm"
                            sx={{
                                bgcolor: "rgba(117,117,117, 0.1)",
                                fontSize: "1rem",
                            }}
                            onClick={() => setEditPhoneModalOpen(true)}
                        >
                            <EditRounded fontSize="inherit" />
                        </IconButton> */}
          </Box>
          <Box
            sx={{
              marginTop: { xs: "30px", sm: "30px", md: "0", lg: "0" },
              width: { xs: "100%", sm: "50%", md: "50%", lg: "50%" },
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
              alignItems: {
                xs: "baseline",
                sm: "baseline",
                md: "center",
                lg: "center",
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <img src="/assets/MailOutlineRounded.svg" />
              <Typography color="#757575">Mail Adresi: </Typography>
            </Box>
            <Typography mx="5px" fontWeight="bold" color="#757575">
              {" "}
              {email || "Bilinmiyor"}{" "}
            </Typography>
            {/* <IconButton
                            size="sm"
                            sx={{
                                bgcolor: "rgba(117,117,117, 0.1)",
                                fontSize: "1rem",
                            }}
                            onClick={() => setEditMailModalOpen(true)}
                        >
                            <EditRounded fontSize="inherit" />
                        </IconButton> */}
          </Box>
        </Box>
        <Divider sx={{ marginTop: "30px" }}></Divider>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              width: { xs: "100%" },
              alignItems: { xs: "baseline" },
            }}
          >
            <Typography marginTop="30px" color="#DC804F">
              Şifre İşlemleri
            </Typography>
            <Typography
              sx={{
                color: "#757575",
                "&:hover": {
                  opacity: 0.7,
                },
                cursor: "pointer",
              }}
              onClick={() => setChangePasswordModalOpen(true)}
              component="u"
            >
              Şifremi değiştirmek istiyorum
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              width: { xs: "100%" },
              alignItems: { xs: "baseline" },
            }}
          >
            <Typography marginTop="30px" color="#DC804F">
              Api Key
            </Typography>
            {userType === "BossUser" || user?.role === "ROLE_BOSS" ? (
              <>
                {apiKey ? (
                  <Typography mx="5px" fontWeight="bold" color="#757575">
                    {apiKey}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: "#757575",
                      "&:hover": {
                        opacity: 0.7,
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => apiKeyGenerate()}
                    component="u"
                  >
                    Api key üretmek için tıklayın.
                  </Typography>
                )}
              </>
            ) : (
              <Typography color="#757575">
                Api key sadece yönetici hesapları için kullanılabilir.
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>

      <CustomDialog
        open={individualEditModalOpen}
        onClose={() => setIndividualEditModalOpen(false)}
        title="Düzenle"
        onSubmit={handleEditIndividual}
        buttons={[
          {
            text: "Kaydet",
            props: {
              variant: "contained",
              type: "submit",
            },
          },
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Adı"
              fullWidth
              required={true}
              value={editFirstName}
              onChange={(e) => {
                setEditFirstName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Soyadı"
              fullWidth
              required={true}
              value={editLastName}
              onChange={(e) => {
                setEditLastName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Şehir"
              fullWidth
              required={true}
              select
              value={editIndividualProvince}
              onChange={(e) => {
                setEditIndividualProvince(e.target.value);
              }}
            >
              {illerBireysel &&
                illerBireysel.map((val, i) => {
                  return (
                    <MenuItem key={i} value={val?.provinceName}>
                      {val?.provinceName}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
        </Grid>
      </CustomDialog>

      <CustomDialog
        open={corporateEditModalOpen}
        onClose={() => setCorporateEditModalOpen(false)}
        title="Düzenle"
        onSubmit={handleEditCorporate}
        buttons={[
          {
            text: "Kaydet",
            props: {
              variant: "contained",
              type: "submit",
            },
          },
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Şirket Unvanı"
              fullWidth
              required={true}
              value={editTitle}
              onChange={(e) => {
                setEditTitle(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Şehir"
              fullWidth
              required={true}
              select
              value={editCorporateProvince}
              onChange={(e) => {
                setEditCorporateProvince(e.target.value);
              }}
            >
              {illerKurumsal &&
                illerKurumsal.map((val, i) => {
                  return (
                    <MenuItem key={i} value={val?.provinceName}>
                      {val?.provinceName}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
        </Grid>
      </CustomDialog>

      <CustomDialog
        open={changePasswordModalOpen}
        onClose={() => setChangePasswordModalOpen(false)}
        title="Düzenle"
        onSubmit={handleChangePassword}
        buttons={[
          {
            text: "Kaydet",
            props: {
              variant: "contained",
              type: "submit",
            },
          },
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Eski Parola"
              fullWidth
              type="password"
              required={true}
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Yeni Parola"
              fullWidth
              type="password"
              required={true}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Yeni Parola (Tekrar)"
              fullWidth
              type="password"
              required={true}
              value={newPasswordAgain}
              onChange={(e) => {
                setNewPasswordAgain(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </CustomDialog>
    </Box>
  );
};

export default Page;
