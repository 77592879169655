import axios from "axios";




export const saveVehicleRequest = ({
    vehicleName,
    licensePlate,
    imei,
    maxSpeed,
    fuelType,
    idleAlarmSecond,
    totalDistance,
    maintenanceDistance,
    safeZoneId,
    tireInstallationDate,
    tireReplacementDistance,
    inspectionGivenDate,
    inspectionExpireDate,
    mandatoryInsuranceStartDate,
    mandatoryInsuranceExpireDate,
    comprehensiveInsuranceStartDate,
    comprehensiveInsuranceExpireDate
}) => axios.post(`/vehicle`, {
    vehicleName,
    licensePlate,
    imei,
    maxSpeed,
    fuelType,
    idleAlarmSecond,
    totalDistance,
    maintenanceDistance,
    safeZoneId,
    tireInstallationDate,
    tireReplacementDistance,
    inspectionGivenDate,
    inspectionExpireDate,
    mandatoryInsuranceStartDate,
    mandatoryInsuranceExpireDate,
    comprehensiveInsuranceStartDate,
    comprehensiveInsuranceExpireDate

})





export const vehicleAssignSafeZoneRequest = ({ safeZoneId, vehicleId }) => axios.post(`/vehicle/assign/safeZone`, {
    safeZoneId, vehicleId
})

export const findAllVehiclesRequest = () => axios.get(`/vehicle`)

export const findAllVehiclesPageableRequest = (search, pageable) => axios.get(`/vehicle/pageable?page=${pageable.page}&size=${pageable.size}&search=${search}`)

export const findByIdRequest = ({ id }) => axios.get(`/vehicle/${id}`)

export const updateStatusRequest = ({ id, vehicleStatus }) => axios.get(`/vehicle/${id}/status`, vehicleStatus)

export const setLockRequest = ({ id, value }) => axios.put(`/vehicle/${id}/lock?value=${value}`, { value })

export const updateVehicleRequest = (form) => axios.put(`/vehicle/${form.id}`, form)

export const updateTotalDistanceRequest = ({ id,totalDistanceMeter }) => axios.put(`/vehicle/totalDistance`,{totalDistance:totalDistanceMeter, vehicleId:id})

export const deleteByIdRequest = ({ id }) => axios.delete(`/vehicle/${id}`)
